export interface Decimal {
  value: number;
  decimalPlaces?: number;
}

export const decimal = ({value, decimalPlaces = 6}: Decimal): number => {
  return parseFloat(value.toFixed(decimalPlaces))
}

export const formatNumber = (value: number, decimalPlaces = 3) => {
  return numberWithCommas(parseFloat(value.toFixed(decimalPlaces)))
}

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
