import * as ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

import {excelDateToJavascript} from '../../utils/dateTime'
import {decimal} from '../../utils/numbers'
import {FacilityReportFormValues, FacilityReportRow, ManageShipments, ReportError, ShipmentHashFields} from './types'

type ValidatorFunction = (value: string) => string | undefined;
type ParserFunction = (value: string, additionalParams?: string) => unknown;

export const handleQuantityUnit = (): string => {
  return 'Tonnes'
}

export const handleQuantity = (quantity: string, unit?: string): number => {
  let result
  if (!unit) {
    throw new Error('Invalid unit')
  }

  const amount = Number(quantity)
  switch (unit.toLowerCase()) {
    case 'kilogram':
    case 'kilograms':
    case 'kilos':
    case 'kg':
    case 'kgs':
      result = amount / 1000
      break
    case 'grams':
      result = amount / 1000000
      break
    case 'tonne':
    case 'tonnes':
      result = amount
      break
    default:
      throw new Error('Invalid quantity')
  }

  return decimal({value: result})
}

const validateMaterialFlow = (value: string): string | undefined => {
  if (!['in', 'out'].includes(value.toLowerCase())) {
    return 'Material flow must be IN or OUT'
  }
}

const validateEmailFormat = (email: string): string | undefined => {
  if (email === null || email.length === 0) {
    return 'Email is required'
  }
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return 'Invalid email format'
  }
}

const validateNotEmpty = (value: string): string | undefined => {
  if (value === null || value.length === 0) {
    return 'Value is required'
  }
}

const validateQuantity = (quntity: string) => {
  if (isNaN(Number(quntity)) || Number(quntity) <= 0) {
    return 'Quantity must be a number greater than 0'
  }
}

interface ColumnMapping {
  [key: string]: {
    key: keyof FacilityReportRow;
    validator?: ValidatorFunction;
    parser?: ParserFunction;
    additionalParams?: string;
  };
}

const columnMapping: ColumnMapping = {
  'Facility name': {key: 'facilityName'},
  'Facility name (Mandatory)': {key: 'facilityName'},
  'Facility address': {key: 'facilityAddress'},
  'Facility address (Mandatory)': {key: 'facilityAddress'},
  'Material flow (in/out)': {key: 'materialFlow', validator: validateMaterialFlow},
  'Material flow (in/out) (Mandatory)': {key: 'materialFlow', validator: validateMaterialFlow},
  'Supplier': {key: 'supplier'},
  'Supplier (Mandatory)': {key: 'supplier'},
  'Supplier email': {key: 'supplierEmail', validator: validateEmailFormat},
  'Supplier email (Mandatory)': {key: 'supplierEmail', validator: validateEmailFormat},
  'Waste supplier': {key: 'supplier'},
  'Waste supplier (Mandatory)': {key: 'supplier'},
  'Waste supplier email': {key: 'supplierEmail', validator: validateEmailFormat},
  'Waste supplier email (Mandatory)': {key: 'supplierEmail', validator: validateEmailFormat},
  'Address of waste origin': {key: 'addressOfWasteOrigin'},
  'Address of waste origin (Mandatory)': {key: 'addressOfWasteOrigin'},
  'Address of material origin': {key: 'addressOfWasteOrigin'},
  'Address of material origin (Mandatory)': {key: 'addressOfWasteOrigin'},
  'Shipper': {key: 'shipper'},
  'Shipper (Mandatory)': {key: 'shipper'},
  'Shipper email': {key: 'shipperEmail', validator: validateEmailFormat},
  'Shipper email (Mandatory)': {key: 'shipperEmail', validator: validateEmailFormat},
  'Transportation vehicle': {key: 'transportationVehicle'},
  'Transportation vehicle (Mandatory)': {key: 'transportationVehicle'},
  'Transportation vehicle (Optional)': {key: 'transportationVehicle'},
  'Document type': {key: 'documentType'},
  'Document type (Mandatory)': {key: 'documentType'},
  'Document number': {key: 'documentNumber'},
  'Document number (Mandatory)': {key: 'documentNumber'},
  'Material type': {key: 'materialType'},
  'Material type (Mandatory)': {key: 'materialType'},
  'Material code': {key: 'materialCode'},
  'Material code (Mandatory)': {key: 'materialCode'},
  'Waste code': {key: 'wasteCode'},
  'Waste code (Mandatory)': {key: 'wasteCode'},
  'Date': {key: 'date', parser: excelDateToJavascript},
  'Date (Mandatory)': {key: 'date', parser: excelDateToJavascript},
  'Quantity unit': {key: 'quantityUnit', parser: handleQuantityUnit, validator: validateNotEmpty},
  'Quantity unit (Mandatory)': {key: 'quantityUnit', parser: handleQuantityUnit, validator: validateNotEmpty},
  'Quantity': {key: 'quantity', parser: handleQuantity, additionalParams: 'Quantity unit', validator: validateQuantity},
  'Quantity (Mandatory)': {key: 'quantity', parser: handleQuantity, additionalParams: 'Quantity unit (Mandatory)', validator: validateQuantity}
}

export const createFileFromWorkbook = async (wb: ExcelJS.Workbook, fileName: string): Promise<File> => {
  const buffer = await wb.xlsx.writeBuffer()
  return new File([buffer], `${fileName}.xlsx`, {type: 'application/vnd.ms-excel'})
}

export const saveFileFromWorkbook = async (wb: ExcelJS.Workbook, fileName: string): Promise<void> => {
  wb.xlsx.writeBuffer()
    .then(buffer => saveAs(new Blob([buffer], {type: 'application/vnd.ms-excel'}), `${fileName}.xlsx`))
    .catch(err => console.log('Error writing excel export', err))
}

export const createShipmentManageRows = async (jsonString: string): Promise<ManageShipments[]> => {
  try {
    const parsedArray = JSON.parse(jsonString) as any[]

    const processItem = async (item: any): Promise<ManageShipments> => {
      const itemErrorFields: ReportError[] = []
      const transformedItem: any = {}

      for (const key of Object.keys(item)) {
        const mapping = columnMapping[key]
        if (!mapping) {
          if (columnMapping.hasOwnProperty(key)) {
            itemErrorFields.push({field: key, message: 'Value missing'})
          }
          continue
        }

        const {validator, parser, key: mappingKey, additionalParams} = mapping

        const validateAndParse = () => {
          const validateResponse = validator?.(item[key])
          if (validateResponse) {
            itemErrorFields.push({field: mappingKey, message: validateResponse})
            return
          }

          if (parser) {
            transformedItem[mappingKey] = additionalParams ? parser(item[key], item[additionalParams]) : parser(item[key])
          } else {
            transformedItem[mappingKey] = item[key]
          }
        }

        try {
          validateAndParse()
        } catch (error) {
          itemErrorFields.push({field: mappingKey, message: 'Parsing or validation error'})
        }
      }

      const shipmentHashFields: ShipmentHashFields = {
        tonnes: transformedItem.quantity,
        documentNumber: transformedItem.documentNumber,
        creationDate: transformedItem.date
      }

      return {
        formRow: transformReportRowToFormRow(transformedItem),
        hash: await objectToValidationHash(shipmentHashFields),
        reportRow: transformedItem as FacilityReportRow,
        errors: itemErrorFields,
        formErrors: []
      }
    }

    return await Promise.all(parsedArray.map(processItem))
  } catch (error) {
    throw new Error('Invalid JSON string')
  }
}

export const transformReportRowToFormRow = (shipment: FacilityReportRow): FacilityReportFormValues => {
  return {
    facilityName: shipment.facilityName || '',
    facilityAddress: shipment.facilityAddress || '',
    materialFlow: shipment.materialFlow || '',
    supplierName: shipment.supplier || '',
    supplierEmail: shipment.supplierEmail || '',
    supplierAddress: shipment.addressOfWasteOrigin || '',
    shipperName: shipment.shipper || '',
    shipperEmail: shipment.shipperEmail || '',
    transportationVehicle: shipment.transportationVehicle || '',
    documentType: shipment.documentType || '',
    documentNumber: shipment.documentNumber || '',
    materialType: shipment.materialType || '',
    materialCode: shipment.materialCode || '',
    wasteCode: shipment.wasteCode || '',
    date: shipment.date || '',
    quantity: shipment.quantity,
    quantityUnit: shipment.quantityUnit || ''
  }
}

export const objectToValidationHash = async (obj: ShipmentHashFields) => {
  const input = JSON.stringify(obj)
  const textAsBuffer = new TextEncoder().encode(input)
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  return hashArray
    .map(item => item.toString(16).padStart(2, '0'))
    .join('')
}

export const hasFormChanged = (initialValues: FacilityReportFormValues, currentValues: FacilityReportFormValues) => {
  return !deepEqual(initialValues, currentValues)
}

export const hasErrors = (manage: ManageShipments): boolean => {
  return manage.errors.length > 0 || manage.formErrors.length > 0
}

// Look for formErrors in the ManageShipments object and remove the error with the same key in errors if formErrors has cleared that one
export const clearErrors = (manage: ManageShipments) => {
  // @ts-ignore
  const errors = manage.errors.filter(error => !manage.formErrors.find(formError => formError[error.field] === error.field))
  return {...manage, errors}
}

const deepEqual = (obj1: any, obj2: any) => {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}
