import {ActionButton, AnimatedError, Button, Icon} from '@kwota-cc/shared-components'
import {useCallback, useState} from 'react'
import {useBoolean} from 'usehooks-ts'

import {BILLING_INFO} from '../../../../utils/constants'
import {getFormattedDateString} from '../../../../utils/dateTime'
import {DateFormat} from '../../../../utils/types/dateTime'
import {FileUploadViewState, VIEW_TYPE} from '../../../../utils/types/generics'
import {InvoiceInfo, PaymentStatus} from '../../types'
import InvoiceProperties from './InvoiceProperties'
import {ReviewInvoiceUploadView} from './ReviewInvoiceUploadView'
import {UploadInvoiceView} from './UploadInvoiceView'

interface UploadInvoiceResultViewProps {
  invoiceInfo?: InvoiceInfo | null;
  success?: boolean;
  retryAction?: () => void;
  onReUploadSubmit: (selectedDate: Date, file: File) => void;
}

const UploadInvoiceResultView = ({
  invoiceInfo,
  success,
  retryAction,
  onReUploadSubmit
}: UploadInvoiceResultViewProps) => {
  if (!success) {
    return <ErrorView retryAction={retryAction} />
  }

  if (!invoiceInfo) return null

  const isProvideInvoiceStatus =
    invoiceInfo.paymentStatus === PaymentStatus.PROVIDE_INVOICE
  const {value: showInvoiceProperties, toggle: toggleInvoiceProperties} =
    useBoolean(isProvideInvoiceStatus)
  const [fileUploadViewState, setFileUploadViewState] =
    useState<FileUploadViewState>({type: VIEW_TYPE.UPLOAD})

  const handleOnDrop = useCallback(async (acceptedFiles: File[]) => {
    const [acceptedFile] = acceptedFiles

    if (acceptedFile) {
      return setFileUploadViewState({
        type: VIEW_TYPE.REVIEW,
        file: acceptedFile,
        shipments: []
      })
    }

    return setFileUploadViewState({type: VIEW_TYPE.RESULT, success: false})
  }, [])

  const handleOnDownloadClick = () => {
    /**@TODO download code once the actual api is called */
    console.log('download file')
  }

  const description = `Services related to verification of CO2 emission reduction, ${getFormattedDateString(
    invoiceInfo.uploadedFor.toString(),
    DateFormat.MONTH_YEAR
  )}`

  const handleOnSubmit = (selectedDate: Date, file: File) => {
    onReUploadSubmit(selectedDate, file)
  }

  return (
    <div className="mt-6 space-y-6">
      <div className="space-y-6 sm:space-y-0 sm:items-center sm:space-x-12 sm:flex">
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-kwota-primary-100">
              <Icon icon="carbon" className="fill-kwota-primary-500" />
            </div>
            <span className="text-sm text-kwota-neutral-500 font-inter">
              Carbon credits sold
            </span>
          </div>
          <span className="text-[28px] leading-10 text-kwota-neutral-800 font-titillium font-semibold">
            {invoiceInfo.creditsSold} tonnes
          </span>
        </div>
        <div className="space-y-2">
          <div className="flex items-center space-x-2">
            <div className="flex items-center justify-center w-8 h-8 rounded-full bg-kwota-primary-100">
              <Icon icon="money" className="fill-kwota-primary-500" />
            </div>
            <span className="text-sm text-kwota-neutral-500 font-inter">
              Earned revenue
            </span>
          </div>
          <span className="text-[28px] leading-10 text-kwota-neutral-800 font-titillium font-semibold">
            {invoiceInfo.earnedRevenue} €
          </span>
        </div>
      </div>

      <div className="flex flex-row items-center w-full p-4 border rounded-2xl border-kwota-neutral-300">
        <div className="w-8 h-8 mr-4">
          <Icon icon="pdf" size="full" className="fill-kwota-neutral-800" />
        </div>
        <div className="flex-1">
          <div>
            <p className="text-lg font-normal leading-6 font-titillium text-kwota-neutral-800">
              {invoiceInfo.fileName}
            </p>
            <p className="text-sm text-kwota-neutral-500 font-titillium">
              Uploaded{' '}
              {getFormattedDateString(
                invoiceInfo.uploadedOn.toString(),
                DateFormat.DATE
              )}
            </p>
          </div>
        </div>
        <ActionButton icon="download" onClick={handleOnDownloadClick} />
      </div>

      {isProvideInvoiceStatus ?
        <div className="flex items-center p-4 text-center rounded-lg bg-kwota-warning-100">
          <p className="text-lg leading-6 text-kwota-warning-400 font-titillium">{`Please upload or email ${BILLING_INFO.EMAIL_ADDRESS} an invoice with the details below.`}</p>
        </div>
        :
        <div
          onClick={toggleInvoiceProperties}
          className="flex items-center justify-center p-2 border rounded-lg cursor-pointer h-9 border-kwota-neutral-300"
        >
          <span className="text-sm font-semibold text-kwota-primary-500 font-titillium">
            {`${showInvoiceProperties ? 'Close' : 'See'} invoice properties`}
          </span>
          <Icon
            icon={`${showInvoiceProperties ? 'chevron-up' : 'chevron-down'}`}
            className="fill-kwota-primary-500"
          />
        </div>
      }

      {showInvoiceProperties &&
        <>
          <InvoiceProperties
            amount={invoiceInfo.earnedRevenue}
            description={description}
          />
          {isProvideInvoiceStatus &&
            <RenderReUploadContent
              invoiceInfo={invoiceInfo}
              viewState={fileUploadViewState}
              setViewState={setFileUploadViewState}
              onSubmit={handleOnSubmit}
              onDrop={handleOnDrop}
            />
          }
        </>
      }
    </div>
  )
}

interface ErrorViewProps {
  retryAction?: () => void;
}

const ErrorView = ({retryAction}: ErrorViewProps) => {
  return (
    <div className="flex items-center justify-center flex-1">
      <div className="h-full w-full flex flex-col justify-center items-center text-[#2A3344]">
        <div className="w-32 h-32 mb-[20px]">
          <AnimatedError />
        </div>
        <div className="font-semibold text-3xl mb-[10px]">Error</div>
        <div className="text-lg font-inter">
          We were not able to upload your data.
        </div>
        <div className="text-lg font-inter">
          Try again later or upload another file.
        </div>
        <div className="mt-[10px]">
          <Button btnType="secondary" text="Try again" onClick={retryAction} />
        </div>
      </div>
    </div>
  )
}

interface RenderReUploadContentProps {
  invoiceInfo: InvoiceInfo;
  viewState: FileUploadViewState;
  setViewState: (viewState: FileUploadViewState) => void;
  onSubmit: (selectedDate: Date, file: File) => void;
  onDrop: (acceptedFiles: File[]) => void;
}

const RenderReUploadContent = ({
  invoiceInfo,
  viewState,
  setViewState,
  onSubmit,
  onDrop
}: RenderReUploadContentProps) => {
  switch (viewState.type) {
    case VIEW_TYPE.UPLOAD:
      return <UploadInvoiceView onDrop={onDrop} />
    case VIEW_TYPE.REVIEW:
      return (
        <ReviewInvoiceUploadView
          fileName={viewState.file.name}
          defaultSelectedDate={invoiceInfo?.uploadedFor}
          onSubmitClick={selectedDate =>
            onSubmit(selectedDate, viewState.file)
          }
          onRemoveClick={() => {
            setViewState({type: VIEW_TYPE.UPLOAD})
          }}
        />
      )
    default:
      return null
  }
}

export default UploadInvoiceResultView
