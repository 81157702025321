import * as ExcelJS from 'exceljs'

import {uploadFacilityShipments} from '../dashboard/uploadReport/api'
import {ManageShipments} from './types'
import {createFileFromWorkbook} from './utils'

export const submitReport = async (shipments: ManageShipments[], facilityId: string, producerId: string, callback: () => void) => {
  const workbook = new ExcelJS.Workbook()
  workbook.created = new Date()
  workbook.modified = new Date()
  const worksheet = workbook.addWorksheet('Monthly data template')

  worksheet.columns = [
    {header: 'Facility name', key: 'facilityName'},
    {header: 'Facility address', key: 'facilityAddress'},
    {header: 'Material flow (in/out)', key: 'materialFlow'},
    {header: 'Supplier', key: 'supplier'},
    {header: 'Supplier email', key: 'supplierEmail'},
    {header: 'Address of waste origin', key: 'addressOfWasteOrigin'},
    {header: 'Shipper', key: 'shipper'},
    {header: 'Shipper email', key: 'shipperEmail'},
    {header: 'Transportation vehicle', key: 'transportationVehicle'},
    {header: 'Document type', key: 'documentType'},
    {header: 'Document number', key: 'documentNumber'},
    {header: 'Material type', key: 'materialType'},
    {header: 'Material code', key: 'materialCode'},
    {header: 'Waste code', key: 'wasteCode'},
    {header: 'Date', key: 'date'},
    {header: 'Quantity unit', key: 'quantityUnit'},
    {header: 'Quantity', key: 'quantity'}
  ]

  const rowContent = shipments.map(shipment => {
    return [
      shipment.formRow.facilityName,
      shipment.formRow.facilityAddress,
      shipment.formRow.materialFlow,
      shipment.formRow.supplierName,
      shipment.formRow.supplierEmail,
      shipment.formRow.supplierAddress,
      shipment.formRow.shipperName,
      shipment.formRow.shipperEmail,
      shipment.formRow.transportationVehicle,
      shipment.formRow.documentType,
      shipment.formRow.documentNumber,
      shipment.formRow.materialType,
      shipment.formRow.materialCode,
      shipment.formRow.wasteCode,
      shipment.formRow.date,
      shipment.formRow.quantityUnit,
      shipment.formRow.quantity
    ]
  })
  worksheet.addRows(rowContent)

  const file = await createFileFromWorkbook(workbook, 'Monthly data template')
  uploadFacilityShipments(file, facilityId, producerId).then(() => {
    callback()
  })
}
