import {
  differenceInHours,
  format,
  formatDistance,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns'
import {convertToTimeZone} from 'date-fns-timezone'

import {DateFormat, DateRange, DateRangeEnum} from './types/dateTime'

export const getISOTimeFormat = () => {
  return new Date().toISOString()
}

export const getDateFromTimestamp = (timestamp: string) => {
  return convertToTimeZone(new Date(timestamp), {timeZone: 'Europe/Tallinn'})
}

export const getFormattedDateString = (timestamp: string, dateFormat: DateFormat) => {
  const date: Date = getDateFromTimestamp(timestamp)
  return format(date, dateFormat)
}

export const formatDate = (timestamp: string, dateFormat: DateFormat) => {
  const date: Date = getDateFromTimestamp(timestamp)
  return format(date, dateFormat)
}

export const formatDateToDateFormat = (date: Date, dateFormat: DateFormat) => {
  return format(date, dateFormat)
}

export const getDateListFrom = (monthsAgo = 12) => {
  const dateList = []
  let date = new Date()
  date.setDate(1) // start at the first day of the current month
  for (let i = 0; i < monthsAgo; i++) {
    const year = date.getFullYear()
    const month = date.toLocaleString('default', {month: 'long'})
    const firstDayOfMonth = new Date(year, date.getMonth(), 1)
    const lastDayOfMonth = new Date(year, date.getMonth() + 1, 0)

    dateList.push({
      month: month,
      year: year,
      firstDayOfMonth: firstDayOfMonth,
      lastDayOfMonth: lastDayOfMonth
    })
    date = new Date(date.getFullYear(), date.getMonth() - 1, 1) // create new date object to ensure uniqueness
  }
  return dateList
}

export const getDateOrTimeDiff = (timestamp: string, daysToDisplayTime: number) => {
  const dateNow = new Date()
  const dateEnd = new Date(timestamp)

  const diffInHours = Math.abs(differenceInHours(dateEnd, dateNow))

  if (diffInHours < daysToDisplayTime * 24) {
    return formatDistance(dateEnd, dateNow, {addSuffix: true})
  }

  return getFormattedDateString(timestamp, DateFormat.DATE)
}

export const startOfThisWeek = startOfWeek(new Date(), {weekStartsOn: 1})
export const startOfThisMonth = startOfMonth(new Date())
export const startOfThisYear = startOfYear(new Date())
export const startAll = startOfYear(new Date().setFullYear(2020))

export const getDateRange = (dateRange: DateRangeEnum): DateRange => {
  const now = new Date()
  switch (dateRange) {
    case DateRangeEnum.ALL:
      return {
        from: startAll,
        to: now
      }
    case DateRangeEnum.THIS_WEEK:
      return {
        from: startOfThisWeek,
        to: now
      }
    case DateRangeEnum.THIS_MONTH:
      return {
        from: startOfThisMonth,
        to: now
      }
    case DateRangeEnum.THIS_YEAR:
      return {
        from: startOfThisYear,
        to: now
      }
    default:
      return {
        from: startOfThisWeek,
        to: now
      }
  }
}

export const excelDateToJavascript = (excelDate: any): Date | null => {
  // Try to parse as Excel serial date
  if (typeof excelDate === 'number') {
    const date = new Date(Date.UTC(1899, 11, 30))
    date.setUTCDate(date.getUTCDate() + excelDate)
    return date
  }

  // Try to parse as regular date string
  const dateString = new Date(excelDate)
  if (!isNaN(dateString.getTime())) {
    return dateString
  }

  // Return null if both attempts fail
  return null
}
